import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-wyng-id", "data-wyng-token"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: "wyng-experience",
      "data-wyng-id": _ctx.wyngId,
      "data-wyng-token": _ctx.wyngToken
    }, null, 8, _hoisted_1)
  ]))
}