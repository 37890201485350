
import { useStore } from 'vuex';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const store = useStore();
    const token = store.state.authModule.user.jwt;
    const wyngId = process.env.VUE_APP_WYNG_ID;
    const wyngToken = process.env.VUE_APP_WYNG_TOKEN;

    const onLoaded = () => {
      if (window.wyng) {
        return window.wyng[wyngId].setUserId(token);
      }

      return setTimeout(() => onLoaded(), 300);
    };

    onMounted(() => {
      const wyngScript = document.createElement('script');
      wyngScript.setAttribute(
        'src',
        'https://dnsl4xr6unrmf.cloudfront.net/js/campaign.js',
      );
      wyngScript.async = true;
      wyngScript.addEventListener('load', onLoaded);

      document.head.appendChild(wyngScript);
    });

    return { wyngId, wyngToken };
  },
});
